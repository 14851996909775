<template>
  <div class="wrapper">
    <admin-title title="银行卡"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="银行名称">
          <el-input style="width:300px;" clearable v-model="searchData.bankName" size="small" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.operation_bank_add)"
      style="margin-bottom:20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'BankCardAdd' })"
      >新增
    </el-button>
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, removeBankAPI, changeBankStatusAPI } from './api'
const columns = [
  {
    label: '银行名称',
    prop: 'bankName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['bankName']}</p>
    }
  },

  {
    label: 'logo',
    prop: 'logoUrl',
    minWidth: '120',
    customRender(h, row) {
      return (
        <div>
          <el-image style="width: 60px; height: 60px;display:block;margin:5px 0;" src={row['logoUrl']}></el-image>
        </div>
      )
    }
  },
  {
    label: '添加日期',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },

  {
    label: '状态',
    prop: 'status',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.operation_bank_status)}
            class="switch"
            onChange={() => this.changeStatus(row)}
            v-model={row['status']}
            active-value={true}
            inactive-value={false}
            active-text="启用"
            inactive-text="禁用"
          ></el-switch>
        </div>
      )
    }
  },

  {
    label: '操作',
    width: '120',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <span
            onClick={() => {
              this.handleEdit(row)
            }}
          >
            <el-link
              disabled={!this.isShowBtn(this.AUTH_BTN.operation_bank_edit) || row['status']}
              style="margin-right:10px;"
              underline={false}
              type="primary"
              onClick={() => {
                // this.$router.push({ name: 'BankCardEdit', params: { id: row['id'] } })
              }}
            >
              编辑
            </el-link>
          </span>
          <span
            onClick={() => {
              this.handleRemove(row)
            }}
          >
            <el-link
              disabled={!this.isShowBtn(this.AUTH_BTN.operation_bank_del) || row['status']}
              underline={false}
              type="primary"
              onClick={() => {
                // this.handleRemove(row)
              }}
            >
              删除
            </el-link>
          </span>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      list: [],
      total: 0,
      columns,
      currentPage: 1,
      pageSize: 10,
      searchData: {
        bankName: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { bankName } = this.searchData
      const params = { page: this.currentPage, pageSize: this.pageSize, bankName }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    // 启用/禁用
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status ? true : false }
      let statusDesc = status ? '启用' : '禁用'

      this.$confirm(`此操作将${statusDesc}该银行卡, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status ? true : false
          changeBankStatusAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status ? false : true
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    handleRemove({ id, bankName, status }) {
      if (!this.isShowBtn(this.AUTH_BTN.operation_bank_del)) return
      if (status) {
        this.$alert('【启用】状态下禁止操作！', '提示', {
          confirmButtonText: '确定',
          callback: action => {},
          type: 'warning'
        })
        return
      }
      this.$confirm(`此操作将删除【${bankName}】卡, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          removeBankAPI(id).then(() => {
            this.getList()
            this.$message.success('删除成功')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleEdit({ id, status }) {
      if (!this.isShowBtn(this.AUTH_BTN.operation_bank_edit)) return
      if (status) {
        this.$alert('【启用】状态下禁止操作！', '提示', {
          confirmButtonText: '确定',
          callback: action => {},
          type: 'warning'
        })
        return
      }

      this.$router.push({ name: 'BankCardEdit', params: { id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .el-dialog__body {
    padding: 20px 20px 50px 20px;
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
