import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/admin/sys/bank/page',
    method: 'get',
    params
  })
}
// 添加
export function addBankAPI(data) {
  return http({
    url: '/admin/sys/bank/add',
    method: 'post',
    data
  })
}
// 编辑
export function editBankAPI(data) {
  return http({
    url: '/admin/sys/bank/edit',
    method: 'post',
    data
  })
}
// 详情
export function getBankDetailAPI(id) {
  return http({
    url: '/admin/sys/bank/detail',
    method: 'get',
    params: { id }
  })
}
// 删除
export function removeBankAPI(id) {
  return http({
    url: '/admin/sys/bank/delete',
    method: 'post',
    data: { id }
  })
}
// 状态
export function changeBankStatusAPI(data) {
  return http({
    url: '/admin/sys/bank/editStatus',
    method: 'post',
    data
  })
}
